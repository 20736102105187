.tabs-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.tabs-titles {
    padding-left: 0;
    margin: 0;
    display: inline-flex;
}
.tab-title {
    letter-spacing: 1px;
    padding: 5px;
    list-style: none;
    border: solid 1px #707070;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    min-width: 120px;
}
.tabs-content {
    border-top: none;
    background: #000;
}
.single-tab {
    /*非表示にさせます*/
    display: none;
}
.tab-title.is-active {
    /*tabを上書きして発火させます*/
    background: #3c3c53;
    color: #fff;
    transition: all 0.2s ease-out;
}
.single-tab.is-show {
    /*panelを上書きしてこれだけ表示させます*/
    display: block;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'].prism {
    margin-top: 0;
    border: solid 1px #707070;
    background: #1a1a1a;
    border-radius: 0;
}

.grid {
    display: grid;
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
@media (min-width: 768px) {
    .md\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}
@media (min-width: 1024px) {
    .lg\:grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }
}
.gap-4 {
    gap: 1rem;
}
.text-center {
    text-align: center;
}

blockquote {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.wp-block-quote {
    border: solid 1px #707070;
    background-color: #ffffff48;
    padding: 0 2em 2em 2em;
}

main[data-mode='dark'] a {
    color: #e7e7e7;
}

main[data-mode='light'] a {
    color: #000;
}
